<template>
  <v-container
    fluid
    tag="section"
  >

<base-material-card
      icon="mdi-gift"
      title="Premiação"
      class="px-5 py-3"
    >

<v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Pesquisa"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-data-table
      :headers="premiacoes_eventos"
      :items="premiacoes"
      :items-per-page="24"
      :search="search"
      class="elevation-1">

      <template v-slot:item="row">
          <tr>
            <td style="width: 10%;font-size:14px;">{{row.item.data_insert}}</td>
            <td style="width: 20%;font-size:14px;">{{row.item.evento}} </td>
            <td style="width: 20%;font-size:14px;">{{row.item.promotor}}</td>
            <td style="width: 10%;font-size:14px;">{{row.item.pontos}}</td>
            <td style="width: 10%;font-size:14px;">{{row.item.situacao}}</td>

            <td style="width: 25%;font-size:14px;">
 
              <v-btn style="margin-left:10px"  v-if="row.item.situacao == 'AGUARDANDO'"  :loading="btn_status" color="success" small @click="mudarStatus(row.item, 'APROVADO')">Aprovar
                    <v-icon right dark>mdi-check</v-icon>      
                </v-btn>
                <v-btn style="margin-left:10px"  v-if="row.item.situacao == 'AGUARDANDO'"  :loading="btn_status" color="danger" small @click="mudarStatus(row.item, 'RECUSADO')">Recusar
                    <v-icon right dark>mdi-close</v-icon>      
                </v-btn>         
            </td>
          </tr>
      </template>
    </v-data-table>
</v-card>

    </base-material-card>

     

  

    
    
  </v-container>
</template>
<script>
  import Promotores from '../../../services/promotores'
  import Roles from '../../../services/roles'

  export default {

     created() {
          if(this.$store.state.auth.status.loggedIn == false){
           this.$router.push('/')
          }
      },

    data: () => ({
     
      premiacoes:[],
      msg: '',
      search: '',
      premiacoes_eventos: [
          { text: 'Data', value: 'data_insert' },
          { text: 'Evento', value: 'evento' },
          { text: 'Promotor', value: 'promotor' },
          { text: 'Pontos', value: 'pontos' },
          { text: 'Situação', value: 'situacao' },

        ],

    
    }),

    mounted () {
      this.listar();

    },


    methods: {
      listar () {
       this.loading = true

        Promotores.premiacoes_promotores().then(response => {
            this.premiacoes = response.data
        }).catch(e => {
            if(e.response.status === 401){
            this.$store.dispatch('auth/logout');
            this.$router.push('/restrito/login');
            }

        }).finally(() => {
            this.loading = false

        })

      },


      clear () {       
        this.$refs.form.reset();
      },

      mudarStatus(item, status){
        item.situacao = status
        Promotores.status_solicitar_premiacao(item).then(response => {
            this.msg = response.data
        }).catch(e => {
            if(e.response.status === 401){
            this.$store.dispatch('auth/logout');
            this.$router.push('/restrito/login');
            }

        }).finally(() => {
            this.loading = false

        })
         
      },

    }
  }

</script>
