import axios from 'axios'
const url = 'https://maestrix.grupoparque.com.br:8443/apiprevida/apiopen/'

//const url = 'http://localhost:8093/apiopen/'


export const httpA = axios.create({
     baseURL: url,   
})
export const http = axios.create({
     baseURL: url,   
})
export default {
     url,  httpA,

}