import { http } from './configOpen'
import authHeader from './auth-header';

export default {

    lista: () => {
        return http.get('roles', )
    },

   

}
